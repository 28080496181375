<template>
  <div>
    <b-row align-h="center">
      <b-col cols="6">
        <p class="mb-0 h4" v-text="$t(locale.title)"/>
      </b-col>
      <b-col cols="6" class="d-flex justify-content-end">
        <div
            class="d-flex smallGap text-secondaryColor align-items-center"
            role="button"
            @click="$router.push({ name: 'acountingContracts' })"
        >
          <mdicon :width="25" :height="25" name="arrow-left-thick"/>
          <p class="mb-0" v-text="$t(locale.backTo)"/>
        </div>
      </b-col>

      <b-col cols="12" class="">
        <b-card>
          <div class="d-flex align-content-center justify-content-between">
            <!-- <b-card-title
                            class="mb-0"
                            v-text="$t($route.meta.pageTitle)"
                        /> -->
            <span class="flex items-center gap-3">
              <b-card-title class="mb-0" v-text="$t($route.meta.pageTitle)"/>
              <strong> {{ singleContract.document_number }}</strong>
            </span>

            <div class="d-flex bigGap" v-if="singleContract.contract_date">
              <div class="items-center">
                <p>
                  <span class="mb-0 font-semibold text-blue-900 text-xl"
                  >{{ $t(locale.contractDate) }}: </span
                  ><span>{{ singleContract.contract_date }}</span>
                </p>
              </div>

              <div class="items-center">
                <p>
                  <span class="mb-0 font-semibold text-blue-900 text-xl"
                  >{{ $t(locale.contractEnd) }}: </span
                  ><span>{{ contract_end }}</span>
                </p>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <ValidationObserver ref="form">
      <b-row>
        <b-col cols="12" lg="12">
          <SecondaryCard :title="$t(locale.contractDetails)">
            <b-container fluid>
              <b-row>
                <b-col :cols="12" :lg="4" v-if="hidden_fields.customer_number || hidden_fields.customer_name">
                  <span>
                    <b-form-group
                        :label="$t(locale.customers)"
                        label-for="input-1"
                    >
                      <v-select
                          class="select-size-sm w-full"
                          label="name"
                          v-model="singleContract.customer_name"
                          @option:selected="changeCus($event)"
                          :filterable="false"
                          :disabled="terminated"
                          :options="customersResult"
                          @search="onSearch"
                      >
                        <template slot="no-options">
                          Sök...
                        </template>
                        <template slot="option" slot-scope="option">
                          <div class="d-center">
                            {{
                              option.name + "(" + option.customer_number + ")"
                            }}
                          </div>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                          <div class="selected d-center">
                            {{ option.name }}
                          </div>
                        </template>
                      </v-select>
                    </b-form-group>
                  </span>
                </b-col>
                <b-col cols="12" lg="2" v-if="hidden_fields.contract_date">
                  <validation-provider
                      #default="{ errors }"
                      :rules="'required'"
                      :name="$t(locale.contractDate)"
                  >
                    <b-form-group
                        :label="$t(locale.contractDate)"
                        label-for="input-1"
                    >
                      <date-picker
                          :value="singleContract.contract_date"
                          valueType="format"
                          class="w-100"
                          prefix-class="xmx"
                          :disabled="true"
                          :plaintext="true"
                          :placeholder="$t(locale.contractDate)"
                      />
                      <small class="text-danger" v-text="errors[0]"/>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col cols="12" lg="2" v-if="hidden_fields.period_start">
                  <validation-provider
                      #default="{ errors }"
                      :rules="'required'"
                      :name="$t(locale.periodStart)"
                  >
                    <b-form-group
                        :label="$t(locale.periodStart)"
                        label-for="input-1"
                    >
                      <date-picker
                          :value="singleContract.period_start"
                          valueType="format"
                          class="w-100"
                          :disabled="true"
                          prefix-class="xmx"
                          :placeholder="$t(locale.periodStart)"
                      />
                      <small class="text-danger" v-text="errors[0]"/>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col cols="12" lg="2" v-if="hidden_fields.period_end">
                  <validation-provider
                      #default="{ errors }"
                      :rules="'required'"
                      :name="$t(locale.periodEnd)"
                  >
                    <b-form-group
                        :label="$t(locale.periodEnd)"
                        label-for="input-1"
                    >
                      <date-picker
                          :value="singleContract.period_end"
                          valueType="format"
                          class="w-100"
                          :disabled="true"
                          prefix-class="xmx"
                          :placeholder="$t(locale.periodEnd)"
                      />
                      <small class="text-danger" v-text="errors[0]"/>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col cols="12" lg="2" v-if="hidden_fields.active">
                  <b-form-group :label="$t(locale.active)" label-for="input-1">
                    <LittleTabs
                        :tabs="[
                        {
                          label: 'CustomersCardEdit.yes',
                          active: true,
                          value: true,
                        },
                        {
                          label: 'CustomersCardEdit.no',
                          active: false,
                          value: false,
                        },
                      ]"
                        :defaultValue="true"
                        v-model="singleContract.active"
                        buttonSize="sm"
                        :disabled="terminated"
                        containerStyle="justify-content-start"
                        btnClass="tabWrapperSmall2"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" lg="2" v-if="hidden_fields.continouse">
                  <b-form-group
                      :label="$t(locale.continouse)"
                      label-for="input-1"
                  >
                    <LittleTabs
                        :tabs="[
                        {
                          label: 'CustomersCardEdit.yes',
                          active: true,
                          value: true,
                        },
                        {
                          label: 'CustomersCardEdit.no',
                          active: false,
                          value: false,
                        },
                      ]"
                        :defaultValue="true"
                        v-model="singleContract.continuous"
                        buttonSize="sm"
                        :disabled="terminated"
                        containerStyle="justify-content-start"
                        btnClass="tabWrapperSmall2"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" lg="2" v-if="hidden_fields.contract_length">
                  <b-form-group
                      :label="$t(locale.contractLength)"
                      label-for="input-1"
                  >
                    <b-form-input
                        size="sm"
                        v-model="singleContract.contract_length"
                        v-on:focusout="computeDate"
                        :disabled="terminated"
                        :placeholder="$t(locale.contractLength)"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" lg="2" v-if="hidden_fields.invoiceInterval">
                  <b-form-group
                      :label="$t(locale.invoiceInterval)"
                      label-for="input-1"
                  >
                    <b-form-input
                        size="sm"
                        disabled
                        :readonly="true"
                        :disabled="true"
                        :value="singleContract.invoice_interval"
                        :placeholder="$t(locale.invoiceInterval)"
                    />
                    <!-- :disabled="terminated" -->
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="2" v-if="hidden_fields.our_reference">
                  <b-form-group
                      :label="$t(locale.ourReference)"
                      label-for="input-1"
                  >
                    <b-form-input
                        size="sm"
                        :disabled="terminated"
                        v-model="singleContract.our_reference"
                        :placeholder="$t(locale.ourReference)"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="2" v-if="hidden_fields.your_reference">
                  <b-form-group
                      :label="$t(locale.yourReference)"
                      label-for="input-1"
                  >
                    <b-form-input
                        size="sm"
                        :disabled="terminated"
                        v-model="singleContract.your_reference"
                        :placeholder="$t(locale.yourReference)"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="2" v-if="hidden_fields.your_order_number">
                  <b-form-group
                      :label="$t(locale.yourOrderNumber)"
                      label-for="input-1"
                  >
                    <b-form-input
                        size="sm"
                        v-model="singleContract.your_order_number"
                        :disabled="terminated"
                        :placeholder="$t(locale.yourOrderNumber)"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="2" v-if="hidden_fields.terms_of_payment">
                  <b-form-group
                      :label="$t(locale.paymentTerms)"
                      label-for="input-1"
                  >
                    <v-select
                        :placeholder="$t(locale.paymentTerms)"
                        :options="termsOfPayment"
                        :disabled="terminated"
                        v-model="singleContract.terms_of_payment"
                        @option:selected="
                        computeSelect($event, 'terms_of_payment')
                      "
                        class="select-size-sm w-full resource-selector d-inline-block"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="2" v-if="hidden_fields.terms_of_delivery">
                  <b-form-group
                      :label="$t(locale.deliveryTerms)"
                      label-for="input-1"
                  >
                    <v-select
                        :placeholder="$t(locale.deliveryTerms)"
                        :options="deliveryTerms"
                        :disabled="terminated"
                        v-model="singleContract.terms_of_delivery"
                        @option:selected="
                        computeSelect($event, 'terms_of_delivery')
                      "
                        class="select-size-sm w-full resource-selector d-inline-block"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="2" v-if="hidden_fields.way_of_delivery">
                  <b-form-group
                      :label="$t(locale.deliveryMethod)"
                      label-for="input-1"
                  >
                    <v-select
                        :placeholder="$t(locale.deliveryMethod)"
                        :options="waysOfDelivery"
                        :disabled="terminated"
                        v-model="singleContract.way_of_delivery"
                        @option:selected="
                        computeSelect($event, 'way_of_delivery')
                      "
                        class="select-size-sm w-full resource-selector d-inline-block"
                    />
                  </b-form-group>
                </b-col>
                <!--  -->
                <b-col cols="12" lg="2" v-if="hidden_fields.cost_center">
                  <b-form-group
                      :label="$t(locale.costCenter)"
                      label-for="input-1"
                  >
                    <v-select
                        :placeholder="$t(locale.costCenter)"
                        :options="costCenter"
                        :disabled="terminated"
                        v-model="singleContract.cost_center"
                        @option:selected="computeSelect($event, 'cost_center')"
                        class="select-size-sm w-full resource-selector d-inline-block"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="2" v-if="hidden_fields.project">
                  <b-form-group :label="$t(locale.project)" label-for="input-1">
                    <v-select
                        :placeholder="$t(locale.project)"
                        :options="projects"
                        v-model="singleContract.project"
                        :disabled="terminated"
                        @option:selected="computeSelect($event, 'project')"
                        class="select-size-sm w-full resource-selector d-inline-block"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="2" v-if="hidden_fields.price_list">
                  <b-form-group
                      :label="$t(locale.priceList)"
                      label-for="input-1"
                  >
                    <v-select
                        :placeholder="$t(locale.priceList)"
                        :options="priceLists"
                        :disabled="terminated"
                        v-model="singleContract.price_list"
                        @option:selected="computeSelect($event, 'price_list')"
                        class="select-size-sm w-full resource-selector d-inline-block"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="2" v-if="hidden_fields.currency">
                  <b-form-group
                      :label="$t(locale.currency)"
                      label-for="input-1"
                  >
                    <v-select
                        :placeholder="$t(locale.currency)"
                        :options="currencies"
                        v-model="singleContract.currency"
                        :disabled="terminated"
                        @option:selected="computeSelect($event, 'currency')"
                        class="select-size-sm w-full resource-selector d-inline-block"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="2" v-if="hidden_fields.vat_included">
                  <b-form-group
                      :label="$t(locale.vatIncluded)"
                      label-for="input-1"
                  >
                    <LittleTabs
                        :tabs="[
                        {
                          label: 'CustomersCardEdit.yes',
                          active: true,
                          value: true,
                        },
                        {
                          label: 'CustomersCardEdit.no',
                          active: false,
                          value: false,
                        },
                      ]"
                        :defaultValue="true"
                        v-model="singleContract.vat_included"
                        buttonSize="sm"
                        :disabled="terminated"
                        containerStyle="justify-content-start"
                        btnClass="tabWrapperSmall2"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-container>
          </SecondaryCard>
        </b-col>

        <b-col cols="12" lg="12">
          <SecondaryCard class="mt-2" :title="$t(locale.customerDetails)">
            <b-container fluid>
              <b-row>
                <b-col cols="12" lg="3">
                  <b-form-group
                      :label="$t(locale.customerName)"
                      label-for="input-1"
                  >
                    <b-form-input
                        size="sm"
                        :value="singleContract.customer_name"
                        :readonly="true"
                        :disabled="terminated"
                        :placeholder="$t(locale.customerName)"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="3">
                  <b-form-group
                      :label="$t(locale.customerNumber)"
                      label-for="input-1"
                  >
                    <b-form-input
                        size="sm"
                        :value="singleContract.organisation_number"
                        :readonly="true"
                        :placeholder="$t(locale.customerNumber)"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="3">
                  <b-form-group
                      :label="$t(locale.address1)"
                      label-for="input-1"
                  >
                    <b-form-input
                        size="sm"
                        :value="singleContract.address1"
                        :readonly="true"
                        :disabled="terminated"
                        :placeholder="$t(locale.address1)"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="3">
                  <b-form-group
                      :label="$t(locale.address2)"
                      label-for="input-1"
                  >
                    <b-form-input
                        size="sm"
                        :value="singleContract.address2"
                        :readonly="true"
                        :disabled="terminated"
                        :placeholder="$t(locale.address2)"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="3">
                  <b-form-group :label="$t(locale.phone)" label-for="input-1">
                    <b-form-input
                        size="sm"
                        :value="singleContract.phone1"
                        :readonly="true"
                        :placeholder="$t(locale.phone)"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="3">
                  <b-form-group :label="$t(locale.city)" label-for="input-1">
                    <b-form-input
                        size="sm"
                        :value="singleContract.city"
                        :readonly="true"
                        :disabled="terminated"
                        :placeholder="$t(locale.city)"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="3">
                  <b-form-group :label="$t(locale.zipCode)" label-for="input-1">
                    <b-form-input
                        size="sm"
                        :value="singleContract.zip_code"
                        :readonly="true"
                        :disabled="terminated"
                        :placeholder="$t(locale.zipCode)"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-container>
          </SecondaryCard>
        </b-col>

        <!-- InvoiceRow -->
        <invoiceRowComponentVue
            :invoice_rows="singleContract.invoice_rows"
            :invoice_country="customerInfo.country_code"
            @removeRow="removeRow"
            :key="singleContract.invoice_rows.length"
            @calculateRow="reCalculateValues"
            :booked="singleContract.booked"
            :cancelled="terminated"
            :vat_included="singleContract.vat_included"
            :submitForm="submitForm"
            :locale="locale"
        />

        <b-col cols="12" lg="8">
          <SecondaryCard class="mt-2" :title="$t(locale.otherDetails)">
            <b-container fluid>
              <b-row>
                <b-col cols="12" lg="6" v-if="hidden_fields.freight">
                  <b-form-group
                      :label="$t(locale.shippingCharge)"
                      label-for="input-1"
                  >
                    <b-form-input
                        size="sm"
                        :disabled="terminated"
                        v-model="singleContract.freight"
                        :placeholder="$t(locale.shippingCharge)"
                    />
                  </b-form-group>
                </b-col>

                <!-- <b-col cols="12" lg="6">
                                    <b-form-group
                                            :label="$t(locale.invoiceCharge)"
                                            label-for="input-1"
                                        >
                                        <b-form-input
                                            size="sm"
                                            :placeholder="$t(locale.invoiceCharge)"
                                        />
                                    </b-form-group>
                                </b-col> -->

                <!-- <b-col cols="12" lg="6">
                                    <b-form-group
                                            :label="$t(locale.invoiceDiscount)"
                                            label-for="input-1"
                                        >
                                        <b-form-input
                                            size="sm"
                                            :placeholder="$t(locale.invoiceDiscount)"
                                        />
                                    </b-form-group>
                                </b-col> -->

                <b-col cols="12" lg="6" v-if="hidden_fields.print_template">
                  <b-form-group
                      :label="$t(locale.printoutTemplate)"
                      label-for="input-1"
                  >
                    <v-select
                        :placeholder="$t(locale.printoutTemplate)"
                        v-model="singleContract.print_template"
                        :options="printTemplates"
                        :disabled="terminated"
                        @option:selected="computeSelect($event, 'print_template')"
                        class="select-size-sm w-full resource-selector d-inline-block"
                    />
                  </b-form-group>
                </b-col>

                <!-- <b-col cols="12" lg="6">
                                    <b-form-group
                                            :label="$t(locale.language)"
                                            label-for="input-1"
                                        >
                                        <v-select
                                            :placeholder="$t(locale.language)"
                                            :options="[]"
                                            v-model="singleContract.language"
                                            class="select-size-sm w-full resource-selector d-inline-block"
                                        />
                                    </b-form-group>

                                </b-col> -->

                <b-col cols="12" lg="6" v-if="hidden_fields.remarks">
                  <b-form-group :label="'Remarks'">
                    <b-form-textarea
                        size="lg"
                        v-model="singleContract.remarks"
                        :placeholder="'Remarks'"
                        :disabled="terminated"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-container>
          </SecondaryCard>
        </b-col>

        <b-col cols="12" lg="4">
          <b-card class="mt-2">
            <b-card-text class="d-flex flex-column smallGap">
              <div
                  v-for="(faktur, index) in fakture"
                  :key="index"
                  class="d-flex align-items-center"
              >
                <p class="mb-0" v-text="$t(faktur.label)"/>
                <div class="border-between"/>
                <p class="mb-0" v-text="faktur.value"/>
              </div>

              <div class="sds devider my-2"/>

              <div class="d-flex justify-content-between align-items-center">
                <p class="mb-0 font-weight-bold" v-text="$t(locale.total)"/>

                <p class="mb-0">{{ singleContract.total_to_pay }}</p>
              </div>
            </b-card-text>
          </b-card>
        </b-col>

        <b-col cols="12" class="mt-2">
          <b-row align-v="center">
            <b-col cols="12" lg="6">
              <div class="d-flex bigGap align-items-center"></div>
            </b-col>
            <b-col cols="12" lg="6" class="d-flex smallGap justify-content-end">
              <b-button
                  v-for="(btn, index) in buttonList"
                  :key="index"
                  :variant="btn.variant"
                  :disabled="btn.processing"
                  @click="btn.click(btn)"
              >
                <!-- @click="btn.submit ? updateContract() : $router.go(-1)" -->
                <span>
                  <span v-if="btn.submit">
                    <span v-if="btn.processing">{{
                        $t("Message.loading")
                      }}</span>
                    <span v-else>{{ $t(btn.label) }}</span>
                  </span>
                  <span v-else>{{ $t(btn.label) }}</span>
                </span>
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </ValidationObserver>

    <Modal
        id="modal-terminate-contract"
        :hideFooter="true"
        :title="'Terminate Contract'"
    >
      <b-card-text
          class="d-flex flex-column text-center align-items-center justify-content-center"
          style="gap: 1rem"
      >
        <img
            src="@/assets/duplioAsset/icons/warningCircle.png"
            width="70px"
            height="70px"
        />
        <p class="mb-0 pb-0">
          Är du säker på att du vill avsluta avtal
          {{ singleContract.document_number }}?
        </p>
      </b-card-text>
      <div class="flex justify-end gap-3 mt-2">
        <b-button
            variant="outline-danger"
            size="md"
            @click="$bvModal.hide('modal-terminate-contract')"
        >
          <span v-text=" $t('Message.cancel')"/>
        </b-button>
        <b-button
            variant="primary"
            size="md"
            :disabled="sendingModalAction"
            @click="terminateNow"
        >
          <span
              v-text="sendingModalAction ? $t('Message.loading') :  $t('Message.proceed')"
          />
        </b-button>
      </div>
    </Modal>
    <Modal

        id="modal-access-denied"
        :hideFooter="true"
        :no-close-on-backdrop="true"
        :hide-header="true"
        :hide-back-drop="true"
    >
      <access-denied/>
    </Modal>
  </div>
</template>

<script>
import {ValidationProvider, ValidationObserver} from "vee-validate";
import DatePicker from "vue2-datepicker";
import vSelect from "vue-select";
import debounce from "lodash.debounce";
import {registersMixin} from "@/mixins/registersMixin";
import {contractRow} from "./constants/contracts";
import moment from "moment";
import invoiceRowComponentVue from "./invoiceRowComponent.vue";
import useAppConfig from "@core/app-config/useAppConfig";
import accessDenied from "@core/components/permission/accessDenied";
import {mapGetters} from "vuex";
import PAGES from "@/services/helpers/constant";

export default {
  name: "editContracts",
  mixins: [registersMixin],
  components: {
    vSelect,
    DatePicker,
    ValidationProvider,
    ValidationObserver,
    invoiceRowComponentVue, accessDenied
  },
  data() {
    return {
      config: useAppConfig(),
      updating: false,
      contractTotal: 0,
      hidden_fields: {...JSON.parse(JSON.stringify(PAGES['accounting_data']['contracts']))},
      singleContract: {invoice_rows: []},
      editContractResults: {},
      customerInfo: {},
      terminated: false,
      previousVatIncluded: false,
      contract_end: "",
      submitForm: false,
      sendingModalAction: false,
      fakture: [
        {
          label: "invoicesEditCard.net",
          value: 0,
          fieldValue: "net",
        },
        {
          label: "invoicesEditCard.gross",
          value: 0,
          fieldValue: "gross",
        },
        {
          label: "invoicesCard.table.vat",
          value: 0,
          fieldValue: "total_vat",
        },
        {
          label: "invoicesEditCard.totalExVat",
          value: 0,
          fieldValue: "total",
        },
      ],

      buttonList: [
        {
          label: "UsersCard.cancel",
          value: "cancel",
          variant: "outline-secondary",
          click: this.cancelData,
          processing: false,
          submit: false,
        },
        {
          label: "contractsCard.terminateContract",
          value: "void",
          variant: "danger",
          click: this.terminteContract,
          processing: false,
          submit: false,
        },
        {
          label: "createContract.updateContract",
          value: "save",
          variant: "warning",
          click: this.runUpdateContract,
          processing: false,
          submit: true,
        },
      ],

      locale: {
        contractDetails: "createContract.contractDetail",
        customerDetails: "CustomersCardEdit.customerDetail",
        invoiceRow: "invoicesEditCard.invoiceRows",
        otherDetails: "invoicesEditCard.othersDetails",
        customers: "StatisticsCard.tabs.customers",
        contractDate: "createContract.contractDate",
        contractEnd: "contractsCard.contractEnd",
        periodStart: "createContract.periodStart",
        periodEnd: "createContract.periodEnd",
        active: "createContract.active",
        continouse: "createContract.continouse",
        contractLength: "createContract.contractLength",
        invoiceInterval: "createContract.invoiceInterval",
        ourReference: "InvoiceCardCustomerEdit.ourReference",
        yourReference: "InvoiceCardCustomerEdit.yourReference",
        yourOrderNumber: "invoicesEditCard.yourOrderNumber",
        paymentTerms: "InvoiceCardCustomerEdit.paymentTerms",
        deliveryTerms: "InvoiceCardCustomerEdit.deliveryTerms",
        deliveryMethod: "InvoiceCardCustomerEdit.deliveryMethod",
        costCenter: "createContract.costCenter",
        project: "createContract.project",
        priceList: "invoicesEditCard.priceList",
        currency: "InvoiceCardCustomerEdit.currency",
        vatIncluded: "createContract.vatIncluded",
        shippingCharge: "InvoiceCardCustomerEdit.shippingCharge",
        invoiceCharge: "InvoiceCardCustomerEdit.invoiceCharge",
        invoiceDiscount: "InvoiceCardCustomerEdit.invoiceDiscount",
        printoutTemplate: "invoicesEditCard.printoutTemplate",
        language: "invoicesEditCard.language",
        invoiceText: "InvoiceCardCustomerEdit.invoiceText",
        customerName: "Message.tableHeader.name",
        customerNumber: "invoicesEditCard.organisationNumber",
        address1: "invoicesEditCard.invoiceAddress1",
        address2: "invoicesEditCard.invoiceAddress2",
        phone: "CustomersCard.table.phone",
        city: "BillingPageCard.leftSide.Form.city",
        zipCode: "BillingPageCard.leftSide.Form.zipCode",
        title: "createContract.editContract",
        backTo: "golfRelatedCard.detail.backOverview",
        total: "invoicesCard.table.total",
        dltBtn: "CustomersCardEdit.dltBtn",
      },
      waysOfDelivery: [],
      deliveryTerms: [],
      termsOfPayment: [],
      printTemplates: [],
      priceLists: [],
      currencies: [],
      projects: [],
      costCenter: [],
      customersResult: [],
      articleResults: [],
      accountResults: [],
      selectedCustomer: {},
      options: [
        {
          name: "terms_of_payment",
          options: "termsOfPayment",
        },
        {
          name: "price_list",
          options: "priceLists",
        },
        {
          name: "currency",
          options: "currencies",
        },
        {
          name: "way_of_delivery",
          options: "waysOfDelivery",
        },
        {
          name: "terms_of_delivery",
          options: "deliveryTerms",
        },
        {
          name: "print_template",
          options: "printTemplates",
        },
        {
          name: "project",
          options: "projects",
        },
        {
          name: "cost_center",
          options: "costCenter",
        },
      ],
    };
  },
  computed: {
    message() {
      return this.GET_LOCALE === "sv" ? "swe_message" : "eng_message";
    },
    features() {
      if (this.getLang == "") {
        return this.swedishLang ? "features" : "features";
      } else return this.getLang == "sv" ? "features" : "features";
    },
    getLang() {
      return this.config.lang.value;
    },
    ...mapGetters({
      GET_LOCALE: 'appConfig/getLocale'
    })
  },
  watch: {
    "singleContract.continuous": function (n, o) {
      if (n) {
        this.singleContract.contract_length = null;
      } else {
        this.singleContract.contract_length = 12;
      }
    },
    "singleContract.vat_included": function (newVal, oldVal) {
      this.reCalculateValues(null);
    },
  },
  mounted() {
    this.$store.commit('appConfig/UPDATE_ACCESS_DENIED', false)
    this.getAllRegisters();
    const {id} = this.$route.params;
    if (id) {
      this.getSingleContract(id);
    }
    this.disabledFields()
    this.fetchCustomer()

  },
  methods: {
    openAccessDeniedModal() {
      this.$store.commit('appConfig/UPDATE_ACCESS_DENIED', true)
      this.$bvModal.show("modal-access-denied");

    },
    disabledFields() {
      let hidden_object = this.hiddenResource
      try {
        // this.$useJwt.hiddenResource({method: 'get', URL: `/${hidden_object['id']}/`}, {}).then()
        hidden_object['hidden_fields']['accounting_data']['contracts'].map(item => {
          this.hidden_fields[item] = false
        })
      } catch (e) {

      }
    },
    getSingleContract(uuid) {
      this.$useJwt
          .getContracts({URL: `/${uuid}`, method: "get"})
          .then((res) => {
            this.singleContract = {...res.data.data};

            if (this.singleContract.category === "Finished") {
              this.terminated = true;
            }
            this.previousVatIncluded = this.singleContract.vat_included;
            this.computeDate();
            this.fakture.map((el) => {
              if (this.singleContract[el.fieldValue]) {
                el.value = this.singleContract[el.fieldValue];
              }
            });
            this.reCalculateValues(null)
            this.getCustomerInfo(this.singleContract.customer_uuid);
          }).catch(err => {
        if (err.response.status === 403) {
          window.Bus.$emit('trigger-access-denied', {'data': err.response.data})
        }

      });
    },
    fetchCustomer: function () {
      this.$useJwt.searchCustomers(``)
          .then(res => {
            this.customersResult = res.data.data
          })
          .catch(err => {
          })
    },
    getCustomerInfo(customerNum) {
      this.$useJwt.getCustomersByid(customerNum).then((res) => {
        this.customerInfo = {...res.data.data};
      });
    },
    async getAllRegisters() {
      const waysOfDelivery = await this.$useJwt.getAllRegisters(
          "wayofdelivery"
      );
      const deliveryTerms = await this.$useJwt.getAllRegisters(
          "termsofdelivery"
      );
      const termsOfPayment = await this.$useJwt.getAllRegisters(
          "termsofpayment"
      );
      const printTemplates = await this.$useJwt.getAllRegisters(
          "printtemplates?type=invoice"
      );
      const priceLists = await this.$useJwt.getAllRegisters("pricelists");
      const currencies = await this.$useJwt.getAllRegisters("currencies");
      const costcenter = await this.$useJwt.getAllRegisters("costcenters");
      const projects = await this.$useJwt.getAllRegisters("projects");

      this.waysOfDelivery = waysOfDelivery.data.results.map((el) => {
        return {label: el.description, value: el.code};
      });
      this.deliveryTerms = deliveryTerms.data.results.map((el) => {
        return {label: el.description, value: el.code};
      });
      this.termsOfPayment = termsOfPayment.data.results.map((el) => {
        return {label: el.description, value: el.code};
      });
      this.printTemplates = printTemplates.data.results.map((el) => {
        return {label: el.name, value: el.template, id: el.uuid};
      });
      this.priceLists = priceLists.data.results.map((el) => {
        return {label: el.description, value: el.code};
      });
      this.currencies = currencies.data.results.map((el) => {
        return {label: el.description, value: el.code};
      });
      this.costCenter = costcenter.data.results.map((el) => {
        return {label: el.description, value: el.code};
      });
      this.projects = projects.data.results.map((el) => {
        return {label: el.description, value: el.code};
      });
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.search(loading, search, this);
      }
    },
    search: debounce(function (loading, search, vm) {
      this.$useJwt
          .searchCustomers(`?search=${escape(search)}`)
          .then((res) => {
            this.customersResult = res.data.data;
            loading(false);
          })
          .catch((err) => {
          });
    }, 350),
    changeCus(customer) {
      if (!this.terminated) {
        this.customerInfo = customer;
        if (!customer.active) {
          this.popupMsg(
              this.$t("Message.Failed"),
              "This customer is not active. Please select another customer.",
              "AlertTriangleIcon",
              "danger"
          );
          form.value = "";
          return;
        }
        this.singleContract.customer_number = customer.customer_number;
        this.singleContract.customer_name = customer.name;
        this.singleContract.address1 = customer.address1;
        this.singleContract.address2 = customer.address2;
        this.singleContract.zip_code = customer.zip_code;
        this.singleContract.city = customer.city;
        this.singleContract.organisation_number = customer.organisation_number;
        this.singleContract.phone1 = customer.phone1;
        this.singleContract.city = customer.city;
        this.singleContract.country = customer.country;
        this.singleContract.delivery_name = customer.delivery_name;
        this.singleContract.delivery_address1 = customer.delivery_address1;
        this.singleContract.delivery_address2 = customer.delivery_address2;
        this.singleContract.delivery_zip_code = customer.delivery_zip_code;
        this.singleContract.delivery_city = customer.delivery_city;
        this.singleContract.delivery_date = customer.delivery_date;
        this.singleContract.phone2 = customer.phone2;
        this.singleContract.email_address_to = customer.email_address_to;
        this.singleContract.delivery_city = customer.delivery_city;
        this.singleContract.delivery_country = customer.delivery_country;
      } else {
        this.popupMsg(
            this.$t("Message.Failed"),
            "Contract is terminated",
            "AlertTriangleIcon",
            "danger"
        );
      }
    },
    computeSelect(item, el) {
      if (this.terminated === true) {
        return false
      }
      this.singleContract[el] = item.value;
    },
    switchLittleTabs(index, form, parentIndex) {
      form.tabs.find((item) => item.active == true).active = false;

      form.value = form.tabs[index].value;

      form.tabs[index].active = true;
    },
    computeDate: function () {
      if (this.singleContract.continuous === false) {
        if (this.singleContract.contract_date) {
          this.contract_end = moment(this.singleContract.contract_date)
              .add(this.singleContract.contract_length, "M")
              .format("YYYY-MM-DD");
        } else {
          this.contract_end = "";
        }
      } else {
        this.contract_end = "";
      }
    },
    reCalculateValues(item = null) {
      let total = 0;
      let total_excluding_vat = 0;
      let total_vat = 0;

      this.singleContract.invoice_rows.map((element) => {
        let vat_amount = 0;

        total_excluding_vat += parseFloat(element.total_excluding_vat);

        vat_amount =
            element.total_excluding_vat * (1 + element.vat / 100) -
            element.total_excluding_vat;
        total += parseFloat(
            element.total_excluding_vat * (1 + element.vat / 100)
        );
        total_vat += vat_amount;

        if (this.singleContract.vat_included) {
          element.total = (
              parseInt(element.delivered_quantity) *
              parseFloat(element.price_excluding_vat).toFixed(2) *
              parseFloat(1 + parseFloat(element.vat) / 100).toFixed(2) *
              (
                  1 - parseFloat(this.removePercentage(element.discount) / 100)
              ).toFixed(2)
          ).toFixed(2);
          element.price = (
              parseFloat(element.price_excluding_vat).toFixed(2) *
              parseFloat(1 + parseFloat(element.vat) / 100).toFixed(2)
          ).toFixed(2);
        } else {
          element.total = (
              parseInt(element.delivered_quantity) *
              parseFloat(element.price_excluding_vat).toFixed(2) *
              (
                  1 - parseFloat(this.removePercentage(element.discount) / 100)
              ).toFixed(2)
          ).toFixed(2);

          element.total_excluding_vat = parseFloat(
              element.total_excluding_vat
          ).toFixed(2);

          element.price_excluding_vat = parseFloat(
              element.price_excluding_vat
          ).toFixed(2);

          element.price = parseFloat(element.price_excluding_vat).toFixed(2);
        }
      });

      if (item !== null) {
        if (item.delivered_quantity && item.price_per_unit) {
          if (this.singleContract.vat_included) {
            item.total = (
                parseInt(item.delivered_quantity) *
                parseFloat(item.price_per_unit) *
                (1 -
                    parseFloat(this.removePercentage(item.discount) / 100).toFixed(
                        2
                    ))
            ).toFixed(2);
            item.total_excluding_vat = (
                item.total /
                (1 + parseFloat(item.vat) / 100)
            ).toFixed(2);
          } else {
            item.total_excluding_vat = (
                parseInt(item.delivered_quantity) *
                parseFloat(
                    item.price_per_unit *
                    parseFloat(
                        1 -
                        parseFloat(
                            this.removePercentage(item.discount) / 100
                        ).toFixed(2)
                    ).toFixed(2)
                )
            ).toFixed(2);
            item.total = (
                item.total_excluding_vat *
                (1 + parseFloat(item.vat) / 100)
            ).toFixed(2);
          }

          let vat = 0;
          // total += parseFloat(item.total);
          total_excluding_vat += parseFloat(item.total_excluding_vat).toFixed(
              2
          );

          vat = (item.total - item.total_excluding_vat).toFixed(2);
          total_vat += vat;
        }
      }

      this.singleContract.gross = parseFloat(total).toFixed(2);
      this.singleContract.total_vat = parseFloat(total_vat).toFixed(2);
      this.singleContract.total = parseFloat(total_excluding_vat).toFixed(2);
      this.singleContract.total_to_pay = parseFloat(total).toFixed(2);
      this.fakture[0].value = this.singleContract.total;
      this.fakture[1].value = this.singleContract.gross;
      this.fakture[2].value = this.singleContract.total_vat;
      this.fakture[3].value = this.singleContract.total;
    },
    removeRow(item) {
      // if (this.CURRENT_USER.access === "Read") {
      //     return this.$toast.info(this.$t("limited_read_access"));
      // }

      this.singleContract.invoice_rows.splice(item, 1);
      this.reCalculateValues(null);
    },
    cancelData() {
      this.$router.go(-1);
    },
    terminteContract() {
      // open Cancel confirmation
      this.$bvModal.show("modal-terminate-contract");
    },
    terminateNow() {
      const payload = this.singleContract;
      const mode = {
        URL: `/${this.singleContract.uuid}/finish`,
        method: "put",
      };

      this.sendingModalAction = true;
      this.$useJwt
          .getContracts(mode, payload)
          .then((res) => {
            this.sendingModalAction = false;
            this.getSingleContract(this.singleContract.uuid);
            $bvModal.hide("modal-terminate-contract");
            this.popupMsg(
                this.$t("Message.Success"),
                res.data[this.message],
                "CheckIcon",
                "success"
            );
          })
          .catch((err) => {
            this.sendingModalAction = false;
            this.popupMsg(
                this.$t("Message.Failed"),
                err.response.data[this.message],
                "AlertTriangleIcon",
                "danger"
            );
          });
    },
    runUpdateContract(item) {
      this.$refs.form.validate().then((res) => {
        if (res) {
          this.updateContract(item);
        }
      });
    },
    updateContract(buttonItem) {
      this.submitForm = false;
      // if (this.CURRENT_USER.access === "Read") {
      //     return this.$toast.info(this.$t("limited_read_access"));
      // }
      if (this.singleContract.category === "Finished") {
        this.popupMsg(
            this.$t("Message.Failed"),
            "Terminated contract can not be updated",
            "AlertTriangleIcon",
            "danger"
        );
        return;
      }
      if (
          this.singleContract.contract_date === "" ||
          this.singleContract.period_start === "" ||
          this.singleContract.period_end === "" ||
          this.singleContract.customer_number === ""
      ) {
        this.popupMsg(
            this.$t("Message.Failed"),
            "Astericks Field required",
            "AlertTriangleIcon",
            "danger"
        );
      } else {
        buttonItem.processing = true;

        let payload = this.singleContract;
        let invoice_rows = this.singleContract.invoice_rows;
        let invoice_rows_data = [];
        let data = {};
        invoice_rows.map((item) => {
          let val = item;
          for (const [key, value] of Object.entries(item)) {
            if (value === "" || value === null) {
              delete val[key];
            }
          }
          invoice_rows_data.push(JSON.parse(JSON.stringify(val)));
        });
        // adjust invoice rows before sending to BE
        if (this.previousVatIncluded !== this.singleContract.vat_included) {
          if (this.singleContract.vat_included === true) {
            invoice_rows_data.forEach((item) => {
              // alter invoice row values
              if (item.newRow || item.newRow === true) {
                item.price = parseFloat(item.price).toFixed(2);
                item.total = parseFloat(item.total).toFixed(2);
              } else {
                item.price = parseFloat(
                    item.price / (1 + item.vat / 100)
                ).toFixed(2);
                item.total = parseFloat(
                    item.total / (1 + item.vat / 100)
                ).toFixed(2);
              }
            });
          } else {
            invoice_rows_data.forEach((item) => {
              if (item.newRow || item.newRow === true) {
                item.price = parseFloat(item.price).toFixed(2);
                item.total = parseFloat(item.total).toFixed(2);
              } else {
                item.price = parseFloat(
                    item.price * (1 + item.vat / 100)
                ).toFixed(2);
                item.total = parseFloat(
                    item.total * (1 + item.vat / 100)
                ).toFixed(2);
              }
            });
          }
        }
        for (const [key, value] of Object.entries(payload)) {
          if (key !== "invoice_rows") {
            data[key] = value;
          }
        }
        data["invoice_rows"] = invoice_rows_data;
        // for (const [key, value] of Object.entries(payload)) {
        //   if (key !== "invoice_rows") {
        //     if (value === "" || value === null) {
        //       delete data[key];
        //     }
        //   }
        // }


        const mode = {URL: `/${this.singleContract.uuid}`, method: "put"};

        this.$useJwt
            .getContracts(mode, data)
            .then((res) => {
              buttonItem.processing = false;

              this.getSingleContract(this.singleContract.uuid);
              this.popupMsg(
                  this.$t("Message.Success"),
                  res.data[this.message],
                  "CheckIcon",
                  "success"
              );
            })
            .catch((err) => {
              buttonItem.processing = false;
              this.popupMsg(
                  this.$t("Message.Failed"),
                  err,
                  "AlertTriangleIcon",
                  "danger"
              );
              if (err.response.status === 403) {
                window.Bus.$emit('trigger-access-denied', {'data': err.response.data})
              }
            });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.select-size-sm w-full resource-selector {
  width: 100%;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/datepicker.scss";
</style>

<style scoped>
.border-between {
  display: block;
  border-top: 1px dashed #cbd4e1;
  flex-grow: 1;
  margin: 0 10px;

  margin-top: 2px;
}

.devider {
  border: 1px solid #64748b;
}

/* .v-select .dropdown li a {
  padding: 10px 20px;
  font-size: 1.25em;
  color: #3c3c3c;
} */
</style>
